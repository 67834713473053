import React from "react";
import ApiClient from "../../../services/api/api";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Typography, CircularProgress, Backdrop } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

export default function JobList({project}) {

    const apiClient = new ApiClient("testbook");
    const [jobs, setJobs] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        apiClient.do_list_jobs({project}).then(setJobs)
    }, [project]);
    const saveContent = (url, fileName) => {
        console.log("URL: ", url);
        console.log("FILE NAME: ", fileName);
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        console.log("LINK: ", link);
        link.click();
        setTimeout(() => {
            document.body.removeChild(link);
            setLoading(false); 
        },2000);
      }
    const onDownload = async ({jobId, fileName})=>{
        setLoading(true);
        const psurl = await apiClient.do_prepare_download({jobId});
        const {url} = psurl;

        saveContent(url, fileName);
    };


    const columns = [
        { field: 'fileName', headerName: 'File Name', width: 350 },
        { field: 'overallState', headerName: 'Stato', width: 150 },
        { field: 'creator', headerName: 'Creator', width: 175 },
        { 
            field: 'creationTimestamp', 
            headerName: 'Creation DateTime', 
            width: 175, 
            valueFormatter: (params) => new Date(params*1000).toLocaleString('it-IT', { timeZone: 'Europe/Rome' })
        },
        { field: 'project', headerName: 'Progetto', width: 100 },
        // campo con pulsante per scaricare il file
        { field: 'download', headerName: 'Download', width: 100, type: 'actions', getActions: (params)=>params.row.overallState === "SUCCESS" ? [
            <GridActionsCellItem icon={<DownloadIcon />} onClick={()=>onDownload({jobId: params.id, fileName: params.row.fileName})} label="Download"/>
        ] : [] },
    ];

    // Convert timestamps to integers for sorting
    const rowsWithFormattedDates = jobs.map(job => ({
        ...job,
        creationTimestamp: parseInt(job.creationTimestamp, 10)
    }));

    return <>
        <div style={{ height: '83vh', width: '100%' }}>
            <Typography variant="h6" gutterBottom>Lavori richiesti { project && project !== "Nessuno" ? "nel progetto " + project : "dall'utente" }</Typography>
            <DataGrid rows={jobs} columns={columns} pageSize={5} getRowId={r => r.jobId} initialState={{
                        sorting: {
                            sortModel: [{ field: 'creationTimestamp', sort: 'desc' }]
                        }
                    }} />
        </div>
        <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={loading}>
            <CircularProgress color="inherit" />
            <Typography variant="h6" style={{ marginLeft: '10px' }}>Downloading, please wait...</Typography>
        </Backdrop>
    </>
}