import * as React from 'react';
import { Box, Button, TextField, Input, FormControl, InputLabel, Select, MenuItem, FormHelperText, Chip, Stack, Typography, Autocomplete } from '@mui/material';
import UserService from '../../../services/users/userService.js';
import ProjectService from '../../../services/projects/projectService.js';
import { uploadFileToS3ByPresignedUrl } from '../../../services/aws/s3/s3.js';
import ApiClient from '../../../services/api/api.js';
import { User, OverallCurrentPage } from '../../../context.jsx';

import FormInput from "../../FormInput.jsx";
import { useNavigate } from "react-router-dom";



export default function JobInputForm(props) {
    const [selectedProject, setSelectedProject] = React.useState('Nessuno');
    const [jobName, setJobName] = React.useState('');
    const [ccValues, setCcValues] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const [file, setFile] = React.useState(null);
    const userSvc = React.useMemo(() => new UserService(), []);
    const projSvc = React.useMemo(() => new ProjectService("techdoc"), []);
    const { setCurrentPage } = React.useContext(OverallCurrentPage);
    const [projects, setProjects] = React.useState([]);
    const [ccEmails, setCCEmails] = React.useState([]);
    const [isSubmit, setIsSubmit] = React.useState(false);
    const [jobSubmitStep, setJobSubmitStep] = React.useState(0);
    const [confirmUpload, setConfirmUpload] = React.useState({});
    const apiClient = new ApiClient("techdoc");
    const { fullUser, isAdmin = false } = React.useContext(User);

    React.useEffect(() => {
        projSvc.listProjectsPerUser(fullUser?.tokens?.idToken?.payload?.email, isAdmin).then(p => setProjects(["Nessuno", ...p]));
        userSvc.listUserEmails("techdoc").then(e => setCCEmails(e));
    }, [projSvc, userSvc]);

    const handleProjectChange = (event) => {
        setSelectedProject(event.target.value);
    };

    const handleInputChange = (_, newInputValue) => {
        setInputValue(newInputValue);
    };

    const handleAddCcValue = (event, newValue) => {
        // Avoid adding duplicates
        const emails = newValue.split(',').map(email => email.trim());
        let loc_emails = []
        emails.forEach((email) => {
            if (!ccValues.includes(email)) {
                loc_emails = [...loc_emails, email];
            }
        })
        setCcValues([...ccValues, ...loc_emails])
        setInputValue(''); // Clear input after selection
    };

    const handleCcDelete = (ccToDelete) => () => {
        setCcValues(ccValues.filter(cc => cc !== ccToDelete));
    };

    const fillCCPerProject = async () => {
        if (selectedProject === 'Nessuno') return setCcValues([]);
        setCcValues(
            await projSvc.listUsersPerProject(selectedProject)
        )
    }

    React.useEffect(() => {
        fillCCPerProject();
    }, [selectedProject]);

    const handleFileUpload = (event) => {
        // Handle file upload
        console.log(event.target.files);
        setFile(event.target.files[0]);
    };
    const handleSubmit = async (event) => {
        if (!file) {
            alert('Please select a file to upload.');
            return;
        }
        if (jobName.trim() === "") {
            alert('Please write the service name.');
            return;
        }
        setIsSubmit(true);
        setJobSubmitStep(1);
        event.preventDefault();
        const formData = {
            selectedProject,
            jobName,
            ccValues,
            fileName: file.name
        };
        console.log(formData);
        let prepareUploadResponse = await apiClient.do_prepare_upload(formData);
        setJobSubmitStep(2);
        let { jobId, url: presignedUrl } = prepareUploadResponse;
        console.log("Risposta prepare upload", prepareUploadResponse);
        console.log("Caricamento file", await uploadFileToS3ByPresignedUrl(file, presignedUrl));
        setJobSubmitStep(3);
        await new Promise(r => setTimeout(r, 2000));
        let confirmUploadResponse = await apiClient.do_confirm_upload({ jobId });
        console.log("Risposta confirm upload", confirmUploadResponse);
        setJobSubmitStep(4);
        setConfirmUpload(confirmUploadResponse);

    };

    const navigate = useNavigate();

    return (
        <Stack sx={{ width: "100%" }} alignItems="center" justifyContent="space-evenly">

            {!isSubmit && (
                <Box sx={{ width: '100%', margin: 'auto', display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Stack sx={{ width: '100%' }} alignItems="center" justifyContent="space-evenly">
                        <FormControl fullWidth margin="normal">
                            <FormInput
                                label={"Nome del servizio*"}
                                inputComponent={<>
                                    <TextField
                                        placeholder={"Gestione Utenti..."}
                                        id="service-name"
                                        type="text"
                                        value={jobName}
                                        required={true}
                                        variant="outlined"
                                        onChange={(e) => setJobName(e.target.value)}
                                        sx={{ width: '100%' }}
                                    />
                                </>} />

                            <FormHelperText>Il nome del servizio sarà indicato nel documento di analisi tecnica.</FormHelperText>
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <Box sx={{ width: '100%', margin: 'auto', mt: 5 }}>
                                <FormInput
                                    label={"Progetto"}
                                    inputComponent={
                                        <Select
                                            id="project-select"
                                            value={selectedProject}
                                            onChange={handleProjectChange}
                                            sx={{ width: '100%' }}>

                                            {projects.map((p, pI) => (
                                                <MenuItem key={pI} value={p}>{p}</MenuItem>

                                            ))}
                                        </Select>}
                                />
                                <FormHelperText>Tutti i membri di un progetto potranno visualizzare questo lavoro. Se desideri che il lavoro resti privato scegli 'Nessuno'.</FormHelperText>

                            </Box>
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <Box sx={{ width: '100%', margin: 'auto', mt: 5 }}>
                                <FormInput
                                    label={"Copia conoscenza"}
                                    inputComponent={<>
                                        <Autocomplete
                                            value={null} // Value should be null to clear the input after selection
                                            inputValue={inputValue}
                                            onInputChange={handleInputChange}
                                            onChange={handleAddCcValue}
                                            options={ccEmails}
                                            renderInput={(params) => (
                                                <TextField
                                                    placeholder='Digita le email separate da virgola e premi invio...'
                                                    {...params}
                                                    variant="outlined"
                                                />
                                            )}
                                            freeSolo  // Allow arbitrary input not restricted to the options list
                                        />

                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
                                            {ccValues.map((cc, index) => (
                                                <Chip
                                                    key={index}
                                                    label={cc}
                                                    onDelete={handleCcDelete(cc)}
                                                />
                                            ))}
                                        </Box>
                                    </>} />
                            </Box>
                            <FormHelperText>Questi indirizzi email riceveranno una copia dell'analisi tecnica.</FormHelperText>
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <FormInput
                                label={"OutDoc*"}
                                inputComponent={
                                    <Input
                                        id="file-upload"
                                        type="file"
                                        inputProps={{ accept: 'application/pdf' }}
                                        onChange={handleFileUpload}
                                        sx={{ mt: 2, width: '100%' }}
                                        required={true}
                                    />}
                            />
                            <FormHelperText>Carica il file .pdf dell'OutDoc. Verifica che i link nel documento siano funzionanti e che non ci sia contenuto tagliato.</FormHelperText>
                        </FormControl>

                        <Button variant="contained" sx={{ mt: 3 }} onClick={handleSubmit} disabled={!file}>Crea Riassunto</Button>
                    </Stack>
                </Box>)
            }
            {
                isSubmit && (
                    <>
                        {jobSubmitStep >= 1 && <Typography variant="h4" sx={{ mb: 2 }}>Avvio lavoro...</Typography>}
                        {jobSubmitStep >= 4 && <>
                            <Box textAlign="center">
                                <Typography variant="h4" sx={{ mb: 2 }}>Lavoro confermato!</Typography>
                                <Typography variant="h4" sx={{ mb: 2 }}>Nel frattempo puoi prendere un caffè oppure selezionare una delle seguenti opzioni.</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 3 }}>
                                    <Button variant="contained" sx={{ mt: 3 }} onClick={() => setIsSubmit(false)}>Crea un'altra Analisi Tecnica</Button>
                                    <Button variant="contained" sx={{ mt: 3, ml: 2 }} onClick={() => navigate('/techdoc/jobList')}>Visualizza lavori richiesti</Button>
                                </Box>
                            </Box>
                            
                        </>}
                    </>
                )
            }
        </Stack >
    );
}