import { useState, useMemo, useEffect, useContext } from 'react';
import { Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import ProjectService from '../../services/projects/projectService';
import {User} from '../../context';
import JobList from '../../components/TestBookAgent/jobList/jobList';
const CustomComponent = ({ tabValue }) => {
  // Qui va implementata la logica per renderizzare la componente custom corretta
  return <Paper style={{ padding: '20px' }}>Custom Component for Tab {tabValue}</Paper>;
};

const ListJobs = () => {
  const [tabValue, setTabValue] = useState('Nessuno');
    const [projects,setProjects] = useState(['Nessuno']);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const {fullUser,isAdmin=false} = useContext(User);
  const projSvc = useMemo(() => new ProjectService("testbook"), []);

  useEffect(()=>{
    projSvc.listProjectsPerUser(fullUser?.tokens?.idToken?.payload?.email,isAdmin)
    .then(prjs=>setProjects(['Nessuno',...prjs]));
  },[])

  return (
    <Grid container>
      <Grid item xs={2}>
      <Typography variant="h6">Sfoglia per progetto</Typography>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabValue}
          onChange={handleTabChange}
        >
            {
                (projects || []).map(p=><Tab key={p} label={p} value={p} />)
            } 
        </Tabs>
      </Grid>
      <Grid item xs={10} sx={{px:5}}>
        {tabValue && <JobList project={tabValue} />}
        
      </Grid>
    </Grid>
  );
};

export default ListJobs;