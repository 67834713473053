import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import JobInputForm from '../../components/TechDocGenie/jobInputForm/jobInputForm';

const NewJobPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 6 }}>
      <Typography variant="h4" gutterBottom>
        Genera Analisi Tecnica
      </Typography>
      <Typography variant="body1" gutterBottom>
        Questa pagina di permette di creare una nuova richiesta di generazione di Analisi Tecnica. Fornisci i dettagli necessari per la generazione attraverso i campi seguenti.
      </Typography>
      <Box mt={4}>
        <JobInputForm />
        {/* <NewJobForm placeholder="Placeholder text for the form" /> */}
      </Box>
    </Container>
  );
};

export default NewJobPage;
