import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import { signInWithRedirect, signOut, getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import DrawerAppBar from '../../AppBar';
import Login from '../../Login';
import JobInputForm from '../../components/TechDocGenie/jobInputForm/jobInputForm';


import TechDocListJobs from './listJobs';
import TechDocHomePage from './home';
import TechDocNewJobPage from './newJob';


import { OverallCurrentPage, User } from '../../context';
import useLocalStorage from 'react-use-localstorage';
import useSessionStorageState from 'use-session-storage-state'
import * as ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
    Routes, Route,  
    BrowserRouter as Router,
    Outlet
} from "react-router-dom";

export default function App() {
    const [user, setUser] = useState(null);
    const [fullUser, setFullUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [error, setError] = useState(null);
    const [customState, setCustomState] = useState(null);
    const [currentPage, setCurrentPage] = useSessionStorageState('currentPage', { defaultValue: 'home' });

    const baseroute = "/techdoc";

    /*const routes = [
        {
            path: baseroute + "/",
            element: <TechDocHomePage />,
        },
        {
            path: baseroute + "/home",
            element: <TechDocHomePage />,
        },

        {
            path: baseroute + "/welcome",
            element: <>
                <h1>This is TechDoc Genie</h1>
                <div>Welcome </div>
            </>
        },
        {
            path: baseroute + "/jobInput",
            element: <JobInputForm />
        },
        {
            path: baseroute + "/newJob",
            element: <TechDocNewJobPage />
        },
        {
            path: baseroute + "/jobList",
            element: <TechDocListJobs />
        },

    ];*/

    useEffect(() => {
        const unsubscribe = Hub.listen('auth', ({ payload }) => {
            switch (payload.event) {
                case 'signInWithRedirect':
                    getUser();
                    break;
                case 'signInWithRedirect_failure':
                    setError('An error has occurred during the Oauth flow.');
                    break;
                case 'customOAuthState':
                    setCustomState(payload.data);
                    break;
            }
        });

        getUser();

        return unsubscribe;
    }, []);

    useEffect(() => {
        (async () => {
            if (!!user) {
                const fulluser = await fetchAuthSession();
                console.log(fullUser);
                if (fulluser.tokens.idToken.payload['cognito:groups'].includes('iamidc_bg_kicode_admins')) { //DA VEDERE
                    console.log('is admin');
                    setIsAdmin(true);
                }
                setFullUser(fulluser);
            }
        })();
    }, [user]);

    const getUser = async () => {
        try {
            const currentUser = await getCurrentUser();
            setUser(currentUser);
        } catch (error) {
            console.error(error);
            console.log('Not signed in');
        }
    };

    const navItems = [
        {label: 'Change Application', value: "/"},
        { label: 'Home', value: '/techdoc/home' },
        { label: 'New Job', value: '/techdoc/newJob' },
        { label: 'Jobs List', value: '/techdoc/jobList' }
    ];

    return (
        <div className="App">
                <OverallCurrentPage.Provider value={{ currentPage, setCurrentPage }}>
                    {!!fullUser ? (<User.Provider value={{ fullUser, isAdmin }}>
                        <DrawerAppBar title="TechDoc Genie" navItems={navItems} user={fullUser?.tokens?.idToken?.payload?.email || " "} signOut={signOut} handleMenuClick={(v) => setCurrentPage(v)}>
                            {/* <RouterProvider router={router} /> */}
                            <Outlet/>
                        </DrawerAppBar>
                    </User.Provider>) : (
                        <Login onStartLogin={() => signInWithRedirect({ provider: { custom: 'iamidc' } })} />
                    )}
                </OverallCurrentPage.Provider>
        </div>
    );
}