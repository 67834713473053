import React from 'react';
import { Box, Typography, Button, Grid, Card, CardHeader, CardContent } from '@mui/material';
import { OverallCurrentPage } from '../../context';
import SectionCard from '../../components/sectionCard/sectionCard';
import { useNavigate, Link } from "react-router-dom";

const HomePage = ({
}) => {

    const navigate = useNavigate();

    const { setCurrentPage } = React.useContext(OverallCurrentPage)

    return (
        <Box>
            <Card
                elevation={10}
                sx={{
                    bgcolor: 'white',
                    color: 'black',
                    my: 2,
                    py: 4,
                    px:4,
                    mx: 2,
                    textAlign: 'center',
                    borderRadius: '15px',
                }}
            >
                <Typography variant="h2" component="h1" gutterBottom >
                    TestBook Agent
                </Typography>
                <Typography variant="h5" gutterBottom fontFamily={'Calibri'} >
                    Questa applicazione permette di creare automaticamente dei Test Book a partire da un file di Analisi Funzionale.<br />

                    L'applicazione è in beta preview e non ha ancora tutte le funzionalità. Richieste e suggerimenti sono fortemente graditi.
                </Typography>
                
                <Button variant="contained" color="secondary" sx={{ mt: 4}} onClick={() => navigate("/testbook/newJob")}>
                    Quick start
                </Button>
            </Card>

            <Box sx={{ py: 2, px: 4 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4} sx={{ justifyContent: 'center', display:'flex'}}>
                        <SectionCard title="Come contribuire?" imagePath="/imgs/contribution.jpg">
                        Se hai segnalazioni o richieste apri una <a href="https://github.com/kireply/TestbookAgent/issues" target='_blank'>issue</a> sul repository del progetto.
                        </SectionCard>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ justifyContent: 'center', display:'flex'}}>
                        <SectionCard title="Generazione Test Book" imagePath="/imgs/testbook.jpg">
                            <Typography variant="body1">
                                Puoi creare <Link to="/testbook/newJob">qui</Link> una nuova richiesta di generazione di un Test Book.<br />
                            </Typography>
                            <Typography variant="body1">
                                Oppure puoi verificare i lavori che hai già richiesto <Link to="/testbook/jobList">qui</Link>
                            </Typography>
                        </SectionCard>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ justifyContent: 'center', display:'flex'}}>
                        <SectionCard title="Progetti" imagePath="/imgs/projects_dalle.jpg">
                        <Typography variant="body1">
                                Da qui potrai gestire i progetti. <br />
                            </Typography>
                            <Typography variant="body1">
                                Un progetto raccoglie materiale e persone e rende più facile la collaborazione.<br />
                            </Typography>
                            <Typography color="error" variant="body1">
                                Funzionalità non ancora disponibile.
                            </Typography>
                        </SectionCard>
                    </Grid>
                </Grid>
                
            </Box>
        </Box>
    );
};

export default HomePage;

