class UserService {

    constructor(){
        console.log("US constr");
    }
    
    async listUserEmails(appName) {
        console.log("US lue");
        if (appName === 'testbook'){
            return ['e.carpentieri@reply.it','a.brudaglio@reply.it']
        } else if (appName === 'techdoc'){
            return ['d.anselmo@reply.it', 'a.brudaglio@reply.it']
        }
    }
}

export default UserService;