import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import { Box, Typography, Button, Grid, Card, CardHeader, CardContent } from '@mui/material';
import SectionCard from './components/sectionCard/sectionCard';
import { signInWithRedirect, signOut, getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import DrawerAppBar from './AppBar';
import Login from './Login';

import * as TechDocPages from "./pages/TechDocGenie";
import TechDocApp from "./pages/TechDocGenie/TechDocGenieApp.js"
import * as TestbookPages from "./pages/TestBookAgent";
import TestbookApp from "./pages/TestBookAgent/TestBookApp.js"


import { OverallCurrentPage, User } from './context';
import useLocalStorage from 'react-use-localstorage';
import useSessionStorageState from 'use-session-storage-state'
import * as ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
    Routes, Route,
    // BrowserRouter as Router,
    Link,
    HashRouter as Router,
} from "react-router-dom";



function HomeDisambiguazione() {

    const [user, setUser] = useState(null);
    const [fullUser, setFullUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [error, setError] = useState(null);
    const [customState, setCustomState] = useState(null);
    const [currentPage, setCurrentPage] = useSessionStorageState('currentPage', { defaultValue: 'home' });

    useEffect(() => {
        const unsubscribe = Hub.listen('auth', ({ payload }) => {
            switch (payload.event) {
                case 'signInWithRedirect':
                    getUser();
                    break;
                case 'signInWithRedirect_failure':
                    setError('An error has occurred during the Oauth flow.');
                    break;
                case 'customOAuthState':
                    setCustomState(payload.data);
                    break;
            }
        });

        getUser();

        return unsubscribe;
    }, []);

    useEffect(() => {
        (async () => {
            if (!!user) {
                const fulluser = await fetchAuthSession();
                console.log(fullUser);
                if (fulluser.tokens.idToken.payload['cognito:groups'].includes('iamidc_bg_kicode_admins')) { //DA VEDERE
                    console.log('is admin');
                    setIsAdmin(true);
                }
                setFullUser(fulluser);
            }
        })();
    }, [user]);

    const getUser = async () => {
        try {
            const currentUser = await getCurrentUser();
            setUser(currentUser);
        } catch (error) {
            console.error(error);
            console.log('Not signed in');
        }
    };
    return (
        <Box sx={{
            py: 6,
            px: 6,
            textAlign: 'center'
        }}>
            <DrawerAppBar title="KiCode" navItems={[]} user={fullUser?.tokens?.idToken?.payload?.email || " "} signOut={signOut} handleMenuClick={(v) => setCurrentPage(v)}/>
            <Box sx={{ mb: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Benvenuto nel KiCode dedicato ai progetti per Banca Generali.
                </Typography>
                <Typography variant="h4" gutterBottom>
                    Per iniziare, scegli il tool che desideri utilizzare.
                </Typography>
            </Box>
            <Box sx={{ mt: 4, width: '100%' }}>
                <Grid container spacing={16} justifyContent="center">
                    <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Link to="/techdoc/home">
                            <SectionCard title="TechDoc Genie" imagePath="/imgs/techdoc_home.jpg">
                                <Typography variant="body1">
                                    Crea la tua Analisi Tecnica in pochi click.
                                </Typography>
                            </SectionCard>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Link to="/testbook/home">
                            <SectionCard title="TestBook Agent" imagePath="/imgs/testbook_home.jpg">
                                <Typography variant="body1">
                                    Crea il tuo Test Book in pochi click.
                                </Typography>
                            </SectionCard>
                        </Link>
                    </Grid>
                </Grid>
            </Box>


        </Box>
    )
}


export default function App() {
    const [user, setUser] = useState(null);
    const [fullUser, setFullUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [error, setError] = useState(null);
    const [customState, setCustomState] = useState(null);
    const [currentPage, setCurrentPage] = useSessionStorageState('currentPage', { defaultValue: 'home' });

    const routes = [
        {
            path: "/",
            element: <HomeDisambiguazione />,
            sub_routes: []
        },
        {
            path: "techdoc/*",
            element: <TechDocApp />,
            sub_routes: [
                {
                    path: "home",
                    element: <TechDocPages.HomePage />
                },
                {
                    path: "jobList",
                    element: <TechDocPages.ListJobs />
                },
                {
                    path: "newJob",
                    element: <TechDocPages.NewJobPage />
                },
            ]
        },
        {
            path: "testbook/*",
            element: <TestbookApp />,
            sub_routes: [
                {
                    path: "home",
                    element: <TestbookPages.HomePage />
                },
                {
                    path: "jobList",
                    element: <TestbookPages.ListJobs />
                },
                {
                    path: "newJob",
                    element: <TestbookPages.NewJobPage />
                },
            ]
        }
    ]

    useEffect(() => {
        const unsubscribe = Hub.listen('auth', ({ payload }) => {
            switch (payload.event) {
                case 'signInWithRedirect':
                    getUser();
                    break;
                case 'signInWithRedirect_failure':
                    setError('An error has occurred during the Oauth flow.');
                    break;
                case 'customOAuthState':
                    setCustomState(payload.data);
                    break;
            }
        });

        getUser();

        return unsubscribe;
    }, []);

    useEffect(() => {
        (async () => {
            if (!!user) {
                const fulluser = await fetchAuthSession();
                console.log(fullUser);
                if (fulluser.tokens.idToken.payload['cognito:groups'].includes('iamidc_bg_kicode_admins')) { //DA VEDERE
                    console.log('is admin');
                    setIsAdmin(true);
                }
                setFullUser(fulluser);
            }
        })();
    }, [user]);

    const getUser = async () => {
        try {
            const currentUser = await getCurrentUser();
            setUser(currentUser);
        } catch (error) {
            console.error(error);
            console.log('Not signed in');
        }
    };

    return (
        <div className="App">
            <Router>
                <OverallCurrentPage.Provider value={{ currentPage, setCurrentPage }}>
                    {!!fullUser ? (<User.Provider value={{ fullUser, isAdmin }}>
                        <Routes>
                            {routes.map((route, i) => <Route key={i} path={route.path} element={route.element}>
                                {route.sub_routes.map((sub_route, ii) =>
                                    <Route key={"" + i + "-" + ii} path={sub_route.path} element={sub_route.element} />)}
                            </Route>
                            )}
                        </Routes>

                    </User.Provider>) : (
                        <Login onStartLogin={() => signInWithRedirect({ provider: { custom: 'iamidc' } })} />
                    )}
                </OverallCurrentPage.Provider>
            </Router>
        </div>
    );
}